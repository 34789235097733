.accordion { margin: 0 auto!important;padding: 0!important; list-style: none!important; }
.accordion > li {  counter-increment: item; position: relative;margin:0;padding: 0;}


//.accordion li:after {content: ''; position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background-color: var(--bs-body-color);transform-origin:left; transition:opacity 0.4s}
.accordion.numbered li { counter-increment: section;}
.accordion.numbered li:after {left: 80px;}
.accordion .accordion-heading {margin: 0; padding: 30px 40px 30px 0; line-height: 1.3; position: relative;}

.accordion.tight .accordion-heading {padding:0;}

.accordion .accordion-heading span {display: block; max-width: 86%;}
.accordion > li > a .accordion-heading:after {  height: 12px;  width: 19px; display: block;  position: absolute;  right: 0;  content: ' ';  top: 50%; margin-top: -8px; 
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='10' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M7.33452 4.62905L11.9345 0.0290525L14.1345 2.22905L7.33452 9.02905L0.534522 2.22905L2.73452 0.0290517L7.33452 4.62905Z' fill='%231B2760'/%3E %3C/svg%3E ");
    background-position:center;  transition: transform 0.2s;background-repeat: no-repeat;

}
.accordion > li.active a .accordion-heading:after { transform: rotate(180deg) }

.accordion > li > a .accordion-heading{ 
    align-items: center;
    @include font-size(1.75rem);
}

.accordion > li > a .accordion-heading.small{ 
    align-items: center;
    @include font-size(1.25rem);
}

.accordion.tight .accordion-heading:after {position: relative;margin-top: 0;margin-left: 6px;}

.accordion.numbered .accordion-heading{margin: 0 0 0 80px;}
.accordion > li > a { display: block; width: 100%; cursor: pointer; position: relative; text-decoration: none;}


.accordion li .acc-number{padding-right: 30px;margin-top: 6px;font-weight: 500;font-size: 14px; position: absolute;left: 0}
.accordion li .acc-number::before {content: counter(section, decimal-leading-zero) '/0' attr(data-total);}
.accordion > li ul { padding: 0; margin: 0 0 20px 20px;  list-style: disc;}
.accordion > li ul li { position: relative; padding-bottom: 0; line-height: 1.5; }

.accordion ul li:after { content: ''; font-weight: bold; display: inline-block; width: 4px; height: 4px; background-color: var(--bg-color); position: absolute; left: 0; top: 9px; }
.accordion-item { display: none;padding: 0 0 20px 0; top:0px; position: relative; opacity: 0; transition:opacity 0.4s 0s }
.accordion.numbered .accordion-item  {top: 0;}
.active .accordion-item { opacity: 1;transition:opacity 0.4s 0s }
.accordion.numbered .accordion-item {padding: 0 0 20px 80px;}

.accordion-item.content-section h3 {
    @include font-size(1.5rem);
}

@include media-breakpoint-down(md) { 
    .accordion .accordion-heading {margin: 0; padding: 30px 40px 30px 0; line-height: 1.3; position: relative;}
    .accordion li .acc-number {margin-top: 4px;font-size: 12px}
    .accordion>li>a {
        background-image: none!important;
    }
    .accordion li > a .accordion-heading:before {display: none;}
    .accordion li > a:after { top: 40px;    margin-top: -6px; }
    .accordion.numbered li > a:after { top: 50px;    margin-top: -6px; }
    .accordion.numbered li:after {left: 50px;}
    .accordion-item {padding: 10px 0;top:-20px;}
    .accordion.numbered .accordion-item {padding: 10px 0 10px 50px}
}