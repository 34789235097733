.btn {
    font-family: var(--bs-font-secondary);
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: currentColor;
    height: 52px;
    text-align: center;
    @include font-size(1.2rem);
    font-weight: 700;
    transform: translateZ(0);
    background-color: var(--bs-blue);
    box-shadow: 0px 0px 0px 1px var(--bs-blue) inset;
    transition: background-color 0.4s, transform .4s, box-shadow 0.4s;
}

@include media-breakpoint-up(md) {
    .btn {
        height: 60px;
    }
}


.btn.ghost {
    background-color: transparent;
    color: var(--bs-blue);
}
.btn.ghost.white {
    color: #fff;
    box-shadow: 0px 0px 0px 1px #fff inset;
}

.btn.skew {
    height: 48px;
    font-size: 16px;
    transform: skew(-12deg);
    background-color: #fff;
    color: var(--bs-blue);
    box-shadow: none!important;
}
.btn.skew b {
    padding: 20px 15px;
}

@include media-breakpoint-up(xxl) {
    .btn.skew {
        height: 56px;
        font-size: 18px;
    }
    .btn.skew b {
        padding: 20px 25px;
    }
}


.btn.skew:hover {
    background-color: var(--bs-blue);
    color:#fff;
    box-shadow: none!important;
}

.btn.skew b {
    transform: skew(12deg);
}

.btn.sm {
    height: 48px;
    font-size: 16px;
}

.btn:hover {
    background-color: #fff;
    color: var(--bs-blue);
    box-shadow: 0px 0px 0px 1px var(--bs-blue) inset;
}

.btn.ghost:hover {
    background-color: var(--bs-blue);
    color: #fff;
    box-shadow: 0px 0px 0px 1px var(--bs-blue) inset;
}
.btn.ghost.white:hover {
    box-shadow: 0px 0px 0px 1px #fff inset;
    background-color: rgba(255,255,255,0.1);
}


.btn strong,
.btn b {
    font-weight: 600;
    flex-grow:1
}

.btn path {
    transition: fill 0.4s;
}

.btn em {
    position: relative;
    display: block;
    font-style: normal;
    height: 100%;
    overflow: hidden;
    width: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.4s;
}

@include media-breakpoint-up(md) {
    .btn em {
        width: 60px;
    }
}


.btn em.border-right {
    border-right: 1px solid currentColor;
}

.btn em.border-left {
    border-left: 1px solid currentColor;
}

.btn.sm em {
    width: 48px;
}


.btn:hover em {
    color: var(--bs-blue);
}



.btn:hover em path{
    fill: var(--bs-green);
}

.btn.ghost:hover em {
    color: #fff;
}


.btn em i {
    //overflow: hidden;
    color: var(--bs-green);
    transition: color 0.4s;
    display: flex;
    align-items: center;
}



.btn em svg {
    will-change: transform;
    top: -2px;
}

.btn:hover em svg {
    animation-name: btnHover;
    animation-duration: .4s;
    animation-timing-function: ease;
    will-change: transform;
}


@keyframes btnHover {
    0%,
    to {
        transform: translateX(0) scale(1)
    }

    50% {
        transform: translateX(103%) scale(0)
    }

    50.0001% {
        transform: translateX(-103%) scale(1)
    }
}
@keyframes btnHover2 {
    0%,
    to {
        transform: translateX(0) scale(1)
    }

    50% {
        transform: translateX(-103%) scale(0)
    }

    50.0001% {
        transform: translateX(103%) scale(1)
    }
}



.btn:hover em.top-to-bottom svg {
    animation-name: btnHoverTB;
    animation-duration: .4s;
    animation-timing-function: ease;
    will-change: transform;
}
@keyframes btnHoverTB {
    0%,
    to {
        transform: translateX(0) scale(1)
    }

    50% {
        transform: translateY(103%) scale(0)
    }

    50.0001% {
        transform: translateY(-103%) scale(1)
    }
}


// Span flip
.btn b {
    padding: 20px 15px;
}
@include media-breakpoint-up(md) {
    .btn b {
        padding: 20px 25px;
    }
}


.btn strong {
    overflow: hidden;
    display: block;
    line-height: 1;
}

.btn span {
    position: relative;
    z-index: 1;
    display: inline-block;
    top: -1px;
    will-change: transform;
}

.btn:hover span {
    animation-name: animFlip;
    animation-duration: .4s;
    animation-timing-function: ease;
    //animation-timing-function: var(--animation-primary-expo-2);
}

@keyframes animFlip {

    0%,
    to {
        transform: translateY(0);
    }

    99% {
        transform: translateY(-100%);
    }

    99.0001% {
        transform: translateY(0);
    }
}

.btn span:after {
    color: currentColor;
    transition: all .3s;
    position: absolute;
    mix-blend-mode: difference;
    top: 100%;
    left: 0;
    content: attr(data-hover);
    transform: translateY(0);
}


// Link button
.btn-link {
    font-family: var(--bs-font-secondary);
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: currentColor;
    text-align: center;
    @include font-size(1.25rem);
    font-weight: 700;
    transform: translateZ(0);
}

.btn-link strong,
.btn-link b {
    font-weight: 600;
}


// Span flip
.btn-link b {
    padding: 10px;
    display: inline-block;
    flex-grow: 1;
}

.btn-link strong {
    overflow: hidden;
    display: block;
    line-height: 1;
}

.btn-link span {
    position: relative;
    z-index: 1;
    display: inline-block;
    top: -1px;
    will-change: transform;
}

.btn-link:hover span {
    animation-name: animFlip;
    animation-duration: .4s;
    animation-timing-function: ease;
    //animation-timing-function: var(--animation-primary-expo-2);
}

.btn-link span:after {
    color: currentColor;
    transition: all .3s;
    position: absolute;
    mix-blend-mode: difference;
    top: 100%;
    left: 0;
    content: attr(data-hover);
    transform: translateY(0);
}

.btn-link em {
    position: relative;
    display: block;
    font-style: normal;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.4s;
}


.btn-link em i {
    transition: color 0.4s;
    display: flex;
    align-items: center;
}



.btn-link em svg {
    will-change: transform;
}
.btn-link:hover em svg {
    animation-name: btnHover;
    animation-duration: .4s;
    animation-timing-function: ease;
}
.btn-link:hover em.top-to-bottom svg {
    animation-name: btnHoverTB;
    animation-duration: .4s;
    animation-timing-function: ease;
}