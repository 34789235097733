//  This hides the quick glimpse before the loader starts
.loader-screen {
   pointer-events: none;
   position: fixed;
   z-index: 99999;
   inset:0;
   transition: opacity 0.3s;
   background-color: var(--bs-blue);
}

.loading-container {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   overflow: hidden;
   z-index: 9999;
   pointer-events: none;
   display: flex;
   justify-content: center;
   align-items: center;
   perspective: 640px;
   background-color: var(--bs-blue);
}

.loading-container .loader-mask {
   position: fixed;
   width: 50%;
   height: 100%;
   max-width: 300px;
   overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 9999;
   transform-origin: center center;
}

.logoMove {
   display: flex;
   align-items: center;
   z-index: 999999;
   opacity: 1;
   width: 100%;
}
