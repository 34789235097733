// Innitial load avoiding the jump
.carousel {
    opacity: 0;
    transition: opacity 0.6s
}

.flickity-enabled.carousel {
    opacity: 1;
}

// Always make sure the inner items goes full width
.sliderNav li{
    position: relative;
}

.sliderNav li > div {
    visibility: hidden;
    pointer-events: none;
    transition: visibility 0.8s 0s;
}

//.sliderNav li.is-removed > div {}
.sliderNav li.is-selected > div {
    visibility:visible;
    pointer-events: auto;
}

// Arrows
.slider-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.4s;
}

.sliderNavPrev {
    justify-content: flex-start;
}

.sliderNavNext {
    justify-content: flex-end;
}

.slider-arrow {
    position: relative;
    transition: transform 0.4s, color 0.4s;
    color: currentColor;
    cursor: pointer;
    color: #8D93AF;
    width: 66px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px solid #8D93AF;
    transition: border-color 0.4s, color 0.4s;
}

.slider-arrow:hover {
    color: var(--bs-green);
    border-color: var(--bs-blue);
}

.slider-arrow:after {
    //content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background-color: currentColor;
    transition: transform .4s;
    transform: scaleX(0);
}

.slider-arrow:hover:after {
    transform: scaleX(1);
}

.slider-arrow em {
    overflow: hidden;
    will-change: transform;
}



.sliderNavPrev {
    transform-origin: right;
}
.sliderNavPrev:hover em {
    animation-name: sliderNavPrev;
    animation-duration: .4s;
    animation-timing-function: ease;
    will-change: transform;
}
@keyframes sliderNavPrev {
    0%, to {
        transform: translateX(0) scale(1)
    }
    50% {
        transform: translateX(-103%) scale(0)
    }
    50.0001% {
        transform: translateX(103%) scale(1)
    }
}


.sliderNavNext:hover {
    
}

.sliderNavNext:hover em {
    animation-name: sliderNavNext;
    animation-duration: .4s;
    animation-timing-function: ease;
    will-change: transform;
}
@keyframes sliderNavNext {
    0%, to {
        transform: translateX(0) scale(1)
    }
    50% {
        transform: translateX(103%) scale(0)
    }
    50.0001% {
        transform: translateX(-103%) scale(1)
    }
}

// Nav bullets
.nav-bullet {
    display: block;
    min-width: 16px;
    min-height: 16px;
    background-color: #D1D3D4;
    border-radius: 99px;
    transition: background-color 0.4s;
}
.nav-bullet:hover {
    background-color: #999;
}
// Indicator mover
.indicator {
    position: absolute;
    z-index: 1;
    min-width: 16px;
    min-height: 16px;
    background-color: currentColor;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 99px;
    transition: left var(--animation-smooth), width var(--animation-smooth);
}


// Cicle progress bar
.pagination-progress {
    width: 60px;
    min-width: 40px;
    max-width: 60%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background: conic-gradient(currentColor calc(var(--progress) * 1%), var(--bs-gray-500) calc(var(--progress) * 1%));
    display: flex;
}

@include media-breakpoint-up(xl) {
    .pagination-progress {
        width: 75px;
    }
}

.progress-counter {
    background-color: #fff;
    width: calc(100% - 6px);
    aspect-ratio: 1 / 1;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding-left: 2px;
    font-weight: $headings-font-weight;
    color: var(--bs-heading-color);
}

// Custom height
@include media-breakpoint-up(xl) {
    .slide-heading {
        display: flex;
        align-items: center;
        min-height: var(--use-height-progress);
    }
}

// Number progress
[slider-numbers] {
    height: 24px;
    width: 12px;
    position: relative;
    overflow: hidden;
}

[slider-numbers] li {
    position: absolute;
    bottom: 0;
    transform: translateY(101%);
}

[slider-numbers] li.is-selected {
    transition: transform var(--animation-smooth);
    transform: translatey(0);
}

[slider-numbers] li.is-removed {
    transition: transform var(--animation-smooth);
    transform: translatey(-101%);
}

// CUSTOM SHIT
/*************************** Mask image ***************************/
[slider-mask]{
    position: absolute!important;
    inset:0;
    clip-path: inset(0% 0% 0% 0%);
    transition: clip-path var(--animation-smooth);
}

[slider-mask] img{
    scale: 1.2;
    transition:scale var(--animation-smooth);
    transform-origin: center;
}

[slider-mask].is-removed {
    z-index: 2;
    clip-path: inset(0% 100% 0% 0%);
}

[slider-mask].is-removed img{
    scale: 1;
}

[slider-mask].is-selected {
    z-index: 1;
}

[slider-mask].is-selected img{
    scale: 1;
}

/*************************** Fade ***************************/
[slider-fade] {
    opacity: 0;
    transition: opacity 0.6s 0s;
}

.is-removed [slider-fade] {
    opacity: 0;
}

.is-selected [slider-fade]{
    opacity: 1;
    transition: opacity 0.6s 0.4s;
}

/*************************** Move zoom in anoud out ***************************/
[slider-zIndex2] {
    opacity: 0;
    transform: translateY(20%) scale(1.2);
    transform-origin: center top;
    transition: opacity 0.3s 0.3s, transform 0.6s 0s ease;
}

[slider-zIndex2].is-removed {
    opacity: 0;
    transform: translateY(-20%) scale(0.8);
}

[slider-zIndex2].is-selected {
    opacity: 1;
    transition: opacity 0.2s 0.6s, transform 1s 0.4s ease;
    transform: translateY(0) scale(1);
}

/*************************** Move on X ***************************/
[slider-move-on-x] {
    opacity: 0;
    transform: translateX(15px);
    transition: opacity 0.4s 0s, transform 0.6s 0s;
}

.is-removed [slider-move-on-x] {
    //transform-origin: bottom;
    transform: translateX(-15px);
}

.is-selected [slider-move-on-x] {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.4s 0.6s, transform 0.6s 0.6s;
}

/*************************** Move on Y ***************************/
[slider-move-on-y] {
    opacity: 0;
    transform: translateY(15px);
    transition: opacity 0.4s 0s, transform 0.6s 0s;
}

.is-removed [slider-move-on-y] {
    transform: translateY(-15px);
}

.is-selected [slider-move-on-y] {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.4s 0.6s, transform 0.6s 0.6s;
}

/*************************** Letter animation ***************************/
[slider-letter] .char {
    display: inline-block;
    opacity: 0;
    transform: translateY(-15%);
    transition: transform 0.6s, opacity 0.4s;
    will-change: transform, opacity;
}

.is-selected [slider-letter] .char {
    opacity: 1;
    transform: translateY(0);
}

.is-removed [slider-letter] .char {
    opacity: 0;
    transform: translateY(15%);
}

$char-count: 40; // Adjust this to the number of chars you have
$base-delay: 0.4s;
$delay-increment: 0.04s;
$delay-increment-2: 0.01s;

@for $i from 1 through $char-count {
    .is-selected [slider-letter] .char:nth-child(#{$i}) {
        transition-delay: $base-delay + ($i - 1) * $delay-increment;
    }
}

@for $i from 1 through $char-count {
    .is-removed [slider-letter] .char:nth-child(#{$i}) {
        transition-delay: 0s + ($i - 1) * $delay-increment-2;
    }
}

/*************************** Words animation ***************************/
.sliderNav .split-words .single-word-inner {
    display: inline-block;
    transform: translateY(120%) rotate(0deg)!important;
    will-change: transform, rotate;
    transition: none;
}

.sliderNav .is-selected .split-words .single-word-inner {
    transform: translateY(0) rotate(0deg)!important;
    transition: transform var(--animation-primary);
}

.sliderNav .is-removed .split-words .single-word-inner {
    transform: translateY(-120%) rotate(0deg)!important;
    transition: transform var(--animation-primary);   
}

$word-count: 40;
$base-delay: 0.15s;
$delay-increment: 0.005s;
$delay-increment-2: 0.005s;

@for $i from 1 through $word-count {
    .sliderNav .is-selected .single-word:nth-child(#{$i}) .single-word-inner {
        transition-delay: $base-delay + ($i - 1) * $delay-increment;
    }
}

@for $i from 1 through $word-count {
    .sliderNav .is-removed .single-word:nth-child(#{$i}) .single-word-inner {
        transition-delay: 0s + ($i - 1) * $delay-increment-2;
    }
}

/*************************** Slider cards ***************************/
[slider-card] {
    //transform: translateY(-15px);
    transform-origin:center left;
    transition: opacity var(--animation-smooth), transform var(--animation-smooth), margin var(--animation-smooth);
}

[slider-card].is-removed {
    //transform: translateY(15px);
}

[slider-card].is-selected {
    opacity: 1;
    transform: translateY(0);
    transition: opacity var(--animation-smooth), transform var(--animation-smooth), margin var(--animation-smooth);
}

[slider-card] picture {
    opacity: 0.4;
    transition: opacity var(--animation-smooth);
}

[slider-card].is-selected picture {
    opacity: 1;
}

// Mixin to generate styles for active slides
@mixin active-slide($slide-number, $child-number, $opacity: 1, $translate-x: 0, $scale: 1, $margin-left: null, $rotate: 0deg) {
    [data-active-slide="#{$slide-number}"] [slider-card]:nth-child(#{$child-number}) {
        opacity: $opacity;
        transform: translateX($translate-x) scale($scale) rotate($rotate);
        @if $margin-left != null {
            margin-left: calc((var(--use-width-card) * -1) - #{$margin-left});
        }
    }
}

// Generate styles for each active slide
@include active-slide(2, 1, 1, 2px, 0.9, null, 0deg);
@include active-slide(2, 2, 1, 0, 1, 0.5rem);

@include active-slide(3, 1, 1.0, 4px, 0.8, null, 0deg);
@include active-slide(3, 2, 1, 2px, 0.9, 0.5rem, 0deg);
@include active-slide(3, 3, 1, 0, 1, 0.5rem);

@include active-slide(4, 1, 1.0, 4px, 0.8, null, 0deg);
@include active-slide(4, 2, 1.0, 4px, 0.8, 0.5rem, 0deg);
@include active-slide(4, 3, 1, 2px, 0.9, 0.5rem, 0deg);
@include active-slide(4, 4, 1, 0, 1, 0.5rem);

@include active-slide(5, 1, 1.0, 4px, 0.8, null, 0deg);
@include active-slide(5, 2, 1.0, 4px, 0.8, 0.5rem, 0deg);
@include active-slide(5, 3, 1.0, 4px, 0.8, 0.5rem, 0deg);
@include active-slide(5, 4, 1, 2px, 0.9, 0.5rem, 0deg);
@include active-slide(5, 5, 1, 0, 1, 0.5rem);

@include active-slide(6, 1, 1.0, 4px, 0.8, null, 0deg);
@include active-slide(6, 2, 1.0, 4px, 0.8, 0.5rem, 0deg);
@include active-slide(6, 3, 1.0, 4px, 0.8, 0.5rem, 0deg);
@include active-slide(6, 4, 1.0, 4px, 0.8, 0.5rem, 0deg);
@include active-slide(6, 5, 1, 2px, 0.9, 0.5rem, 0deg);
@include active-slide(6, 6, 1, 0, 1, 0.5rem);

@include active-slide(7, 1, 1.0, 4px, 0.8, null, 0deg);
@include active-slide(7, 2, 1.0, 4px, 0.8, 0.5rem, 0deg);
@include active-slide(7, 3, 1.0, 4px, 0.8, 0.5rem, 0deg);
@include active-slide(7, 4, 1.0, 4px, 0.8, 0.5rem, 0deg);
@include active-slide(7, 5, 1.0, 4px, 0.8, 0.5rem, 0deg);
@include active-slide(7, 6, 1, 2px, 0.9, 0.5rem, 0deg);
@include active-slide(7, 7, 1, 0, 1, 0.5rem);

@include active-slide(8, 1, 1.0, 4px, 0.8, null, 0deg);
@include active-slide(8, 2, 1.0, 4px, 0.8, 0.5rem, 0deg);
@include active-slide(8, 3, 1.0, 4px, 0.8, 0.5rem, 0deg);
@include active-slide(8, 4, 1.0, 4px, 0.8, 0.5rem, 0deg);
@include active-slide(8, 5, 1.0, 4px, 0.8, 0.5rem, 0deg);
@include active-slide(8, 6, 1.0, 4px, 0.8, 0.5rem, 0deg);
@include active-slide(8, 7, 1, 2px, 0.9, 0.5rem, 0deg);
@include active-slide(8, 8, 1, 0, 1, 0.5rem);

// Slider bg color
.slider-bg-color {
    background-color: paleturquoise;
    transition: background-color 0.6s 0s;
}
[data-active-slide="2"].slider-bg-color {
    background-color: papayawhip;
}
[data-active-slide="3"].slider-bg-color {
    background-color: pink;
}
[data-active-slide="4"].slider-bg-color {
    background-color: palegreen;
}
[data-active-slide="5"].slider-bg-color {
    background-color: palevioletred;
}
[data-active-slide="6"].slider-bg-color {
    background-color: palegoldenrod;
}

// Responsive activated sliders
[slider-xxl]:after, [slider-xl]:after, [slider-lg]:after , [slider-md]:after  {
    content: '';
}

@include media-breakpoint-down(xxl) {
    [slider-xxl]:after {
        content: 'flickity';
        display: none;
    }
}

@include media-breakpoint-down(xl) {
    [slider-xl]:after {
        content: 'flickity';
        display: none;
    }
}

@include media-breakpoint-down(lg) {
    [slider-lg]:after {
        content: 'flickity';
        display: none;
    }
}

@include media-breakpoint-down(md) {
    [slider-md]:after {
        content: 'flickity';
        display: none;
    }
}

@include media-breakpoint-down(sm) {
    [slider-sm]:after {
        content: 'flickity';
        display: none;
    }
}

