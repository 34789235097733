:root {
    --lity-z-index-base: 9990;
    --lity-shadow: none;
    --lity-close-size: 35px;
    --lity-close-text-shadow: none;
    --lity-iframe-ratio: 9/16; /* Or 3/4 */
    --lity-transition-timing-function: ease;
    --lity-transition-duration: .4s;
}

.lity-active,
.lity-active body {
    overflow: hidden;
}

.lity {
    z-index: var(--lity-z-index-base);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    background: rgba(0,0,0,.6);
    backdrop-filter: blur(20px);

    outline: none !important;
    opacity: 0;
    transition: opacity var(--lity-transition-duration) var(--lity-transition-timing-function);
}

.lity.lity-opened {
    opacity: 1;
}

.lity.lity-closed {
    opacity: 0;
}

.lity * {
    box-sizing: border-box;
}

.lity-wrap {
    z-index: var(--lity-z-index-base);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    outline: none !important;
    overflow-y: auto;
}

.lity-wrap:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
}

.lity-loader {
    z-index: calc(var(--lity-z-index-base) + 1);
    color: #fff;
    position: absolute;
    top: 50%;
    margin-top: -0.8em;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    opacity: 0;
    transition: opacity var(--lity-transition-duration) var(--lity-transition-timing-function);
}

.lity-loading .lity-loader {
    opacity: 0;
}

.lity-container {
    z-index: calc(var(--lity-z-index-base) + 2);
    position: relative;
    text-align: left;
    vertical-align: middle;
    display: inline-block;
    white-space: normal;
    max-width: 100%;
    max-height: 100%;
    outline: none !important;
}

.lity-content {
    z-index: calc(var(--lity-z-index-base) + 3);
    width: 100%;

    transform: scale(1);
    transition: transform var(--lity-transition-duration) var(--lity-transition-timing-function), opacity 1s;
    opacity: 1;
}

.lity-loading .lity-content,
.lity-closed .lity-content {
    transform: scale(1);
    opacity: 0;
}

.lity-content:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: var(--lity-shadow);
}

.lity-close {
    z-index: calc(var(--lity-z-index-base) + 4);
    width: var(--lity-close-size);
    height: var(--lity-close-size);

    /* Change to position: absolute to display close button inside content container */
    position: fixed;
    right: 0;
    top: 0;

    -webkit-appearance: none;
    cursor: pointer;

    text-decoration: none;
    text-align: center;
    padding: 0;
    color: #fff;
    font-style: normal;
    font-size: var(--lity-close-size);
    font-family: Arial, Baskerville, monospace;
    line-height: var(--lity-close-size);
    text-shadow: var(--lity-close-text-shadow);
    border: 0;
    background: none;
    box-shadow: none;
}

.lity-close::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.lity-close:active {
    //top: 1px;
    transform: scale(1.05);
}

/* Image */

.lity-image img {
    max-width: 100%;
    display: block;
    line-height: 0;
    border: 0;
}

/* iFrame */

.lity-iframe .lity-container {
    width: 100%;
    max-width: 1464px;
    padding: 0 var(--padding-container);
}

.lity-iframe-container {
    width: 100%;
    height: 0;
    padding-top: calc(var(--lity-iframe-ratio) * 100%);
    overflow: auto;
    pointer-events: auto;
    transform: translateZ(0);
    -webkit-overflow-scrolling: touch;
    max-height: 100vh;
    //border-radius:30px
}

.lity-iframe-container iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: var(--lity-shadow);
    background: #000;
    //border-radius: 20px;
    max-height: 100vh;
}

.lity-hide {
    display: none;
}


// Custom
.lity-container {
    width: 100%;
    pointer-events: none;
}

.lity-content video {
    width: auto;
    max-width: 100%;
    max-height: 100vh;
    display: block;
    pointer-events: auto;
    margin: auto;
}

@include media-breakpoint-down(lg) {
    .lity-content video {
        max-width: calc(100% - 12vw);
    }
}


body .lity-close {
    mix-blend-mode: difference;
    width:40px!important;
    height:40px!important;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cg%3E %3Cpath d='M20.0015 4.00098L4.00146 20.001' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E %3Cpath d='M19.9962 19.999L3.99951 3.99902' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E %3C/g%3E %3C/svg%3E")!important;
    pointer-events: auto!important;
    background-size: cover;
    background-position: center!important;
    background-repeat: no-repeat!important;
    font-size: 0!important;
    background-color: var(--color-dark-blue);
    border-radius: 999px;
    right: 10px;
    top: 10px;
    opacity: 1;
    transition:background-color 0.4s
}

body .lity-close:hover {
    opacity: 1;
    background-color: var(--color-orange);
}

.lity-close-holder {position: absolute;top: 10px;right: 10px; background-color: #fff; border-radius:999px; width: 40px;height: 40px;display: flex; align-items: center;justify-content: center; display: none; }
.lity-close-holder svg {pointer-events: none;}
.lity-close-holder path {stroke:var(--color-dark-blue); opacity: 1;}

@include media-breakpoint-up(md) {
    .lity-close-holder {top: 30px;right: 30px; background-color: transparent;}
    .lity-close-holder path {stroke:rgba(255,255,255,0.5); transition: stroke 0.4s}
    .lity-close-holder:hover path {stroke:rgba(255,255,255,1);}
}
