:root {
    --bs-body-color: var(--bs-blue);
    --bs-body-bg:#fff;
    --bs-font-sans-serif: 'Inter', sans-serif;
    --bs-font-secondary: 'Space Grotesk', sans-serif;


    --bs-text-xxl:26px;
    --bs-text-xl:24px;
    --bs-text-lg:20px;
    --bs-text-sm:16px;
    --bs-text-xsm:14px;

    --bs-purple: #704BDB;
    --bs-light-purple: #E8E9EF;
    --bs-yellow:#EFD100;
    --bs-orange:#EF8137;
    --bs-light-orange:#FDF2EB;
    --bs-light:#D1D3D4;
    --bs-lighter:#F5F5F5;
    --bs-lightest:#F6F7F7;
    --bs-light-gray:#E8E9EF;
    --bs-blue:#1B2760;
    --bs-light-blue:#E8F9FD;


    --bs-navy:#1C1B1F;
    --bs-cyan:#17C3E7;
    --bs-light-cyan:#E8F9FD;
    --bs-green:#48D591;
    --bs-light-green:#EDFBF4;

    --bs-purple-rgb: 112, 75, 219;
    --bs-blue-rgb: 27, 39, 96;

    --bs-border-color:var(--bs-blue);


    --bs-gradient: linear-gradient(180deg, rgba(var(--bs-purple-rgb), 1) 5.45%, rgba(var(--bs-purple-rgb), 1) 132.62%);
    --animation-primary: 0.4s cubic-bezier(.75, 0, .25, 1);
    --animation-primary-expo:1s cubic-bezier(0.87, 0, 0.13, 1);
    --animation-fast: 0.2s cubic-bezier(.75, 0, .25, 1);
    --animation-smooth: 0.6s cubic-bezier(.75, 0, .25, 1);
    --animation-ease: 0.735s ease;
    --animation-ease-fast: 0.3s ease;

    --animation-primary-expo-2: cubic-bezier(0.87, 0, 0.13, 1);

    --corner-width: calc(2vw + 30px);

}


@include media-breakpoint-up(sm) {
    :root {
        --bs-body-font-size:18px;
    }
}

body {
    text-wrap: pretty;
}

.bg-purple {
    background-color: var(--bs-purple)!important;
}
.bg-light-purple {
    background-color: var(--bs-light-purple)!important;
}
.bg-yellow {
    background-color: var(--bs-yellow)!important;
}
.bg-orange {
    background-color: var(--bs-orange)!important;
}
.bg-light-orange {
    background-color: var(--bs-light-orange)!important;
    --bs-border-color: var(--bs-orange);
}

.bg-light {
    background-color: var(--bs-light)!important;
}
.bg-lighter {
    background-color: var(--bs-lighter)!important;
}
.bg-lightest {
    background-color: var(--bs-lightest)!important;
}
.bg-blue {
    background-color: var(--bs-blue)!important;
}
.bg-light-blue {
    background-color: var(--bs-light-blue)!important;
    --bs-border-color: var(--bs-blue);
}
.bg-navy {
    background-color: var(--bs-navy)!important;
}
.bg-cyan {
    background-color: var(--bs-cyan)!important;

}
.bg-light-cyan {
    background-color: var(--bs-light-cyan)!important;
    --bs-border-color: var(--bs-cyan);
}
.bg-green {
    background-color: var(--bs-green)!important;
}
.bg-light-green {
    background-color: var(--bs-light-green)!important;
    --bs-border-color: var(--bs-green);
}

.bg-gradient {
    background: var(--bs-gradient)!important;
}
.bg-light-gray {
    background-color: var(--bs-light-gray)!important;
}

@include media-breakpoint-up(md) {
    .bg-lines {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='37' height='39' viewBox='0 0 37 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M0.213379 0.199219L0.213379 38.1484' stroke='%23ffffff' stroke-width='1' stroke-opacity='0.3'/%3E %3Cpath d='M37.0044 0.322266L0.216412 0.322266' stroke='%23ffffff' stroke-width='1' stroke-opacity='0.3'/%3E %3C/svg%3E ");    
        background-repeat: round;
    }
    .bg-lines-2 {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='37' height='39' viewBox='0 0 37 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M0.213379 0.199219L0.213379 38.1484' stroke='%23cccccc' stroke-width='1' stroke-opacity='0.3'/%3E %3Cpath d='M37.0044 0.322266L0.216412 0.322266' stroke='%23cccccc' stroke-width='1' stroke-opacity='0.3'/%3E %3C/svg%3E ");    
        background-repeat: round;
    }
    .bg-lines-3 {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='37' height='39' viewBox='0 0 37 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M0.213379 0.199219L0.213379 38.1484' stroke='%233FB287' stroke-width='1' stroke-opacity='0.3'/%3E %3Cpath d='M37.0044 0.322266L0.216412 0.322266' stroke='%233FB287' stroke-width='1' stroke-opacity='0.3'/%3E %3C/svg%3E ");    
        background-repeat: round;
    }
    .bg-lines-4 {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='37' height='39' viewBox='0 0 37 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M0.213379 0.199219L0.213379 38.1484' stroke='%23d6d6d6' stroke-width='1' stroke-opacity='0.3'/%3E %3Cpath d='M37.0044 0.322266L0.216412 0.322266' stroke='%23d6d6d6' stroke-width='1' stroke-opacity='0.3'/%3E %3C/svg%3E ");    
        background-repeat: round;
    }
}


.text-purple {
    color: var(--bs-purple)!important;
}
.text-yellow {
    color: var(--bs-yellow)!important;
}
.text-orange {
    color: var(--bs-orange)!important;
}
.text-light {
    color: var(--bs-light)!important;
}
.text-blue {
    color: var(--bs-blue)!important;
}
.text-navy {
    color: var(--bs-navy)!important;
}
.text-cyan {
    color: var(--bs-cyan)!important;
}
.text-green {
    color: var(--bs-green)!important;
}

// Content
.content {
    overflow: clip;
}

// Section afters
@include media-breakpoint-down(md) {
    .section-after:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right:0;
        width: 100%;
    }
    .section-after-1:after {
        height: calc(var(--use-float-image-height) * 0.35);
        background-color: var(--bs-green);
    }
    .section-after-2:after {
        height: calc(var(--use-float-image-height-6) * 1);
        background-color: #fff;
    }
    .section-after-3:after {
        height: 20%;
        background-color: #fff;
    }
}

// Floating elements
.element {
    width:calc(var(--padding-container) * 1.2);
    min-width: 100px;
}
.element.bottom-0{
    bottom: -1px!important;
}
.element svg {
    width: 100%;
}

// Floating images
.floating-image {
    position: absolute;
    pointer-events: none;
}
.floating-image-1 {
    z-index: 6;
    max-width: 800px;
    right: 0;
}
@include media-breakpoint-down(md) {
    .floating-image-1 {
        transform: translate(32%, -35%);
        max-width: 500px;
        right: 0;
    }
    .floating-image-1 svg {
        width: 100%;
    }
    .floating-image-height {
        height: calc(var(--use-float-image-height) * 0.65);
    }
}
@include media-breakpoint-up(md) {
    .floating-image-1 svg{
        min-height: 600px;
    }
}

// Image 2
.floating-image-2 {
    left: auto;
    right: 0;
    top: 0;
    width: 20vw;
    transform: translateY(-27.5%);
    max-width: 480px;
}

// Image 3
.floating-image-3 {
    left: auto;
    right: 0;
    top: 0;
    width: 20vw;
    transform: translateY(-28.9%);
    max-width: 480px;
}

// Image 4
.floating-image-4 {
    z-index: 6;
}
@include media-breakpoint-down(md) {
    .floating-image-4 {
        transform: translate(0%, 0%);
        max-width: 130px;
        right: 0;
    }
}
@include media-breakpoint-up(md) {
    .floating-image-4{
        right: 0;
        max-width: 640px;
    }
}

// Image 5
@include media-breakpoint-down(md) {
    .floating-image-5 {
        max-width: 137px;
    }
}
@include media-breakpoint-up(md) {
    .floating-image-5{
        width: 25vw;
        max-width: 430px;
    }
}
// Image 5 2

.floating-image-5-2{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: calc(var(--padding-section) * 1.6);
    justify-content: flex-end;
}
.floating-image-5-2 svg {
    height: 100%;
    object-fit: cover;
}

// Image 6
.floating-image-6 {
    z-index: 6;
    right: 0;
    top: 0;
}
.floating-image-height-6 {
    height: calc(var(--use-float-image-height-6) * 0.8);
    margin-bottom: calc(var(--use-float-image-height-6) * -0.245);
    max-width: 480px;
}
.floating-image-6 svg {
    transform-origin: top left;
    transform: translate(37.4%,-56%);
    //min-height: 420px;
    min-width: 250px;
    width: 20vw;
    max-width: 480px;
}

// Image 6 - 2
.floating-image-6-2 {
    z-index: 6;
    right: 0;
    bottom: calc(var(--corner-height) - 2px);
}
.floating-image-6-2 svg {
    transform-origin: top left;
    transform: translate(100%,00%);
    min-width: 150px;
    width: var(--padding-container);
    max-width: 480px;
}

.floating-image-height-6-2 {
    height: calc(var(--use-float-image-height-6) * 0.5);
    margin-bottom: calc(var(--use-float-image-height-6) * -0.4);
    max-width: 480px;
}



// Image 7
.floating-image-7 {
    z-index: 6;
    left: 0;
}
.floating-image-height-7 {
    height: calc(var(--use-float-image-height-7) * 1);
    margin-top: calc(var(--use-float-image-height-7) * -0.245);
    max-width: 480px;
}
.floating-image-7 svg {
    transform-origin: top left;
    transform: translate(-40.2%,-24.5%);
    //min-height: 420px;
    min-width: 20vw;
    max-width: 400px;
}

// Image 8
.floating-image-8 {
    z-index: 6;
    right: 0;
}
.floating-image-height-8 {
    height: calc(var(--use-float-image-height-8) * 1);
    margin-top: calc(var(--use-float-image-height-8) * -0.245);
    max-width: 480px;
}
.floating-image-8 svg {
    transform-origin: top left;
    transform: translate(40.2%,-24.5%);
    //min-height: 420px;
    min-width: 20vw;
    max-width: 400px;
}

// Image 9
.floating-image-9 {
    z-index: 6;

}
.floating-image-9 svg {
    width: 100%;
}
@include media-breakpoint-down(md) {
    .floating-image-9 {
        top: 80px;
        max-width: 90px;
        right: 0;
    }
}
@include media-breakpoint-up(md) {
    .floating-image-9{
        top: 80px;
        right: 0;
        max-width: 220px;
    }
}


// Image 10
.floating-image-10 {
    z-index: 1;
    left: 0;
    bottom: 0;
}
.floating-image-height-10 {
    height: calc(var(--use-float-image-height-7) * 1);
    margin-top: calc(var(--use-float-image-height-7) * -0.245);
    max-width: 480px;
}
.floating-image-10 svg {
    transform-origin: top left;
    min-width: 20vw;
    max-width: 450px;
}

@include media-breakpoint-up(md) {
    .image-floater {
        position: absolute;
        bottom: 0;
        max-height: calc(100% + 30px);
        z-index: 2;
    
    }
}

@include media-breakpoint-down(md) {
    .image-floater {
        z-index: 2;
        max-width: 60%;
    }
    .floating-image-10 svg {
        max-width: 60%;
    }
}



// Label
.label {
    font-family: var(--bs-font-secondary);
    background-color: var(--bs-blue);
    color: currentColor;
    padding: 8px 10px;
    display: inline-block;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 700;
    margin-bottom: 10px;
}

.label.sm {
    font-size: 14px;
    padding: 6px 8px;
}

// Icons
.icon-1 svg, .icon-1 img {
    width: 44px;
    height: 44px;
}
.icon-2 svg, .icon-2 img {
    width: 44px;
    height: 44px;
}
@include media-breakpoint-up(md) {
    .icon-1 svg, .icon-1 img {
        width: 64px;
        height: 64px;
    }
    .icon-2 svg, .icon-2 img {
        width: 55px;
        height: 55px;
    }
}


// Content sections
.content-section ul {
    padding-left: 20px;
    @include margin-top(1.5rem);
}
.content-section ul li {
    margin-top: 15px;
    line-height: 1.35;
}

.content-section .text-xsm ul {
    @include margin-top(1rem);
}
.content-section .text-xsm ul li {
    margin-top: 6px;
    line-height: 1.35;
}
.content-section h2 {
    @include font-size($h4-font-size);
    font-weight: 700;
}
.content-section h3 {
    @include font-size(1.8rem);
    margin-top: calc(30px + 2vw);
}
.content-section h4 {
    @include font-size(1.5rem);
    font-weight: 400;
}

.content-section blockquote {
    @include padding(4rem);
    background-color: var(--bs-lighter);
    margin-top: calc(30px + 2vw);
    margin-bottom: calc(30px + 2vw);
}

@include media-breakpoint-up(md) {
    .content-section blockquote {
        padding: 80px;
        margin-left: -80px;
        margin-right: -80px;
        width: calc(100% + 160px);
    }
}


.content-section blockquote::before {
    content: "";
    display: block;
    width: 25px;
    height: 20px;
    margin-bottom: 15px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='25' height='20' viewBox='0 0 25 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M22.912 0V4.096H20.544C18.7093 4.096 17.792 5.01333 17.792 6.848V8.896H18.816C20.3947 8.896 21.7173 9.38667 22.784 10.368C23.8507 11.3067 24.384 12.5227 24.384 14.016C24.384 15.5947 23.8507 16.896 22.784 17.92C21.7173 18.9013 20.3947 19.392 18.816 19.392C17.1947 19.392 15.872 18.88 14.848 17.856C13.824 16.832 13.312 15.488 13.312 13.824V6.784C13.312 2.26133 15.552 0 20.032 0H22.912ZM9.6 0V4.096H7.232C5.39733 4.096 4.48 5.01333 4.48 6.848V8.896H5.504C7.08267 8.896 8.40533 9.38667 9.472 10.368C10.5387 11.3067 11.072 12.5227 11.072 14.016C11.072 15.5947 10.5387 16.896 9.472 17.92C8.40533 18.9013 7.08267 19.392 5.504 19.392C3.88267 19.392 2.56 18.88 1.536 17.856C0.512 16.832 0 15.488 0 13.824V6.784C0 2.26133 2.24 0 6.72 0H9.6Z' fill='%231B2760'/%3E %3C/svg%3E ");
    background-size: cover;
}

.content-section blockquote::after {
    content: "";
    display: block;
    justify-content: flex-end;
    align-items: end;
    text-align: right;
    margin-left: auto;
    width: 25px;
    height: 20px;
    margin-top: 15px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='25' height='20' viewBox='0 0 25 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M2.08795 19.392V15.296H4.45593C6.2906 15.296 7.20795 14.3787 7.20795 12.544V10.496H6.18396C4.60529 10.496 3.28261 10.0267 2.21594 9.088C1.14928 8.10667 0.615967 6.86933 0.615967 5.376C0.615967 3.79733 1.14928 2.51733 2.21594 1.536C3.28261 0.512 4.60529 0 6.18396 0C7.80529 0 9.12798 0.512 10.152 1.536C11.176 2.56 11.6879 3.904 11.6879 5.568V12.608C11.6879 17.1307 9.44796 19.392 4.96796 19.392H2.08795ZM15.4 19.392V15.296H17.7679C19.6026 15.296 20.52 14.3787 20.52 12.544V10.496H19.496C17.9173 10.496 16.5946 10.0267 15.528 9.088C14.4613 8.10667 13.928 6.86933 13.928 5.376C13.928 3.79733 14.4613 2.51733 15.528 1.536C16.5946 0.512 17.9173 0 19.496 0C21.1173 0 22.4399 0.512 23.4639 1.536C24.4879 2.56 24.9999 3.904 24.9999 5.568V12.608C24.9999 17.1307 22.76 19.392 18.28 19.392H15.4Z' fill='%231B2760'/%3E %3C/svg%3E ");
    background-size: cover;
}


.content-section-2 a:not(.btn) {
    padding: 8px 0;
    color: currentColor;
    text-decoration: none;
    background-image: linear-gradient(90deg, currentColor, currentColor);
    background-repeat: no-repeat;
    background-position: bottom 6px center;
    background-size: 100% 1px;
    transition: background-size 0.2s ease;
    will-change: background-size;
}
.content-section-2 a:not(.btn):hover {
    background-size: 0% 1px;
    color: inherit;
}

.content-section-2 ul {
    margin-top: 0;
}
.content-section-2 ul li {
    margin-top: 0;
    margin-bottom: 15px;
    line-height: 1.35;
}


.content-section a:not(.btn) {
    display: inline;
    padding: 8px 0;
    color: currentColor;
    text-decoration: none;
    background-image: linear-gradient(90deg, currentColor, currentColor);
    background-repeat: no-repeat;
    background-position: bottom 6px left;
    background-size: 100% 1px;
    transition: background-size 0.2s ease;
    will-change: background-size;
}

.inner-article figure  {
    margin-top: calc(30px + 4vw);
    margin-bottom: calc(30px + 4vw);
}
.inner-article figure .caption {
    font-size: 16px;
    margin-top: 10px;
}

.inner-article img {
    width: 100%;
    height: auto;
}

@include media-breakpoint-up(md) {
    .inner-article  {
        margin: auto;
        max-width: calc(100% - var(--padding-container) * 4);
    }

    .inner-article figure.breakout {
        width: calc(100% + var(--padding-container) * 4);
        margin-left: calc(var(--padding-container) * -2);
    }
}


// Footer

footer a:hover, .fancy-link:hover, .content-section a:not(.btn):hover {
    background-size: 0% 1px;
    color: inherit;
    background-position: bottom 6px right;
}

.content-link {color: currentColor;}
.content-link span{
    color: currentColor;
    text-decoration: none;
    background-image: linear-gradient(90deg, currentColor, currentColor);
    background-repeat: no-repeat;
    background-position: bottom 0px right;
    background-size: 0% 1px;
    transition: background-size 0.2s ease;
    will-change: background-size;
}
.content-section-2 a:not(.btn):hover,  .content-link span:hover {
    background-size: 100% 1px;
    color: inherit;
    background-position: bottom 0px left;
}



.column-2 > div {
    margin-top: 16px;
    break-inside: avoid;
 }
 .column-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:0 16px;
}
.column-2 > div:nth-child(1) {
    order:2
}
.column-2 > div:nth-child(2) {
    order:4;
    grid-column: 1 / span 2;
}
.column-2 > div:nth-child(3) {
    order:1;
    grid-column: 1 / span 2;
}
.column-2 > div:nth-child(4) {
    order:3
}

@include media-breakpoint-up(xl) {
    .column-2 {
        display: block;
        column-count: 2;
        column-gap: 18px;
        break-inside: avoid;
        /// Dont break inside a column
    }
    .column-2 > div {
        margin-top: 18px;
     }
    .column-2 > div:nth-child(1) {
        margin-top: calc(var(--box-height-2) * 0.2);
    }
}

.corner {
    position: absolute;
    transform-origin: center;
    width: var(--corner-width);
    // width: calc(var(--padding-container) * 0.5);
}

.corner.bottom-right {
    bottom: -2px;
    right: -2px;
}
.corner.top-right {
    top: -2px;
    right: -2px;
    rotate:-90deg;
}

.corner.top-left {
    top: -2px;
    rotate:180deg;
    left: -2px;
}

.corner.bottom-left {
    bottom: -2px;
    rotate:90deg;
    left: -2px;
}

@include media-breakpoint-up(xl) {
    .im-left-pad-xl {
        padding-left: var(--corner-width);
    }
    .im-right-pad-xl {
        padding-right: var(--corner-width);
    }
}

// Cards
.card-green {
    --bs-border-color:var(--bs-green);
}
.card-green hr {
    border-color: var(--bs-green);
}

.card-cyan {
    --bs-border-color:var(--bs-cyan);
}
.card-cyan hr {
    border-color: var(--bs-cyan);
}

.card-orange {
    --bs-border-color:var(--bs-orange);
}
.card-orange hr {
    border-color: var(--bs-orange);
}

@include media-breakpoint-up(md) {
    .use-title-height-1 {
        height: var(--use-title-height-1);
    }
}


// List

.list-styled-1 {
    list-style-type: none;
    padding-left: 0;
}

.list-styled-1 > li {
    counter-increment: li;
    display: flex;
    margin-top: 35px;
    position: relative;
}

.list-styled-1 > li::before {
    background-color: #fff;
    z-index: 1;
    font-family: var(--bs-font-secondary);
    @include font-size(3rem);
    flex-shrink: 0;
    width: calc(3vw + 35px);
    height: calc(3vw + 35px);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--bs-blue);
    content: counter(li, decimal-leading-zero);
    color: var(--bs-blue);
    font-weight: 700;
    @include margin-right(1.875rem);
    position: sticky;
    top: calc(var(--nav-height) + 10px);
}

.list-styled-1 > li:after {
    content: "";
    position: absolute;
    display: block;
    width: 20px;
    height: 100%;
    top: calc(3vw + 35px);
    left: calc(calc(1.5vw + 17px));
    width: 1px;
    border-left: 2px dashed #D1D3D4;
}

.list-styled-1 > li:last-child:after {
    display: none;
}


.list-styled-1 ul {
    padding-left: 15px;
    @include margin-top(1rem); 
    margin-left: 0;
    list-style: disc;
    margin-bottom: 0;
}
.list-styled-1 ul li {
    margin-top: 15px;
    line-height: 1.35;
}

.list-styled-1 li div p {
    margin-bottom: 0;
    @include margin-top(1rem); 
}

// Clock

#seconds {
    width: 40px;
}

@include media-breakpoint-up(md) {
    #seconds {
        width: 122px;
    }
}


@include media-breakpoint-up(xxl) {
    #seconds {
        width: 152px;
    }
}


.hr-dashed {
    border-top: 2px dashed #D1D3D4;
}

//Footer
@include media-breakpoint-up(lg) {
    .foot-heading {
        height:var(--foot-heading)
    }
}

footer a, .fancy-link {
    display: inline-block;
    padding: 8px 0;
    color: currentColor;
    text-decoration: none;
    background-image: linear-gradient(90deg, currentColor, currentColor);
    background-repeat: no-repeat;
    background-position: bottom 4px right;
    background-size: 0% 1px;
    transition: background-size 0.2s ease;
    will-change: background-size;
}
.fancy-link {
    display: inline;
}
footer a:hover, .fancy-link:hover {
    background-size: 100% 1px;
    color: inherit;
    background-position: bottom 4px left;
}

h2 .fancy-link {
    background-position: bottom 8px right;
}

h2 .fancy-link:hover {
    background-position: bottom 8px left;
}


.content-section



footer ul li a {
    font-size: 14px;
    font-weight: 500;
}

@include media-breakpoint-down(lg) {
    .col-6-lg-down {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@include media-breakpoint-down(md) {
    .col-12z  {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .list-social {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .list-social li {
        flex: 0 0 50%;
        max-width: 50%;
    }
}



.list-social li a{
    display: flex;
    gap:14px;
    background-image: none;
}

footer ul.list-social li a span {
    display: inline-block;
    color: currentColor;
    text-decoration: none;
    background-image: linear-gradient(90deg, currentColor, currentColor);
    background-repeat: no-repeat;
    background-position: bottom 0px left;
    background-size: 0% 1px;
    transition: background-size 0.2s ease;
}
footer ul.list-social li a:hover span {
    background-size: 100% 1px;
    color: inherit;
}

footer ul.list-social li svg {
    flex-shrink: 0;
}

footer .xx-small a {
    padding: 0;
    background-position: bottom 0px left;
}

[text-split] .word {
    white-space: nowrap;
}

a .hover-image {
    transition: transform 0.5s;
    will-change: transform;
}
a:hover .hover-image {
    transform: scale(1.02);
}

a .hover-zoom {
    overflow: hidden;
}
a .hover-zoom picture {
    transition: transform 0.5s, opacity 0.6s!important;
    will-change: transform;
    transform: scale(1.01);
}
a:hover .hover-zoom  picture{
    transform: scale(1.1);
}

.logo-list {
    height: 90px;
    align-items: center;
}
.logo-list img {
    max-height: 100%;
    max-width: 160px;
    width: 100%;
}

.logo-list.sm {
    height: 50px;
}
.logo-list.sm img {
    max-width: 120px;
}

a:hover .icon-video {
    transform: scale(1.1);
}
.icon-video {
    max-width: 145px;
    width: calc(60px + 4vw);
    transition: transform 0.4s;
}

.quote {
    @include font-size(2rem);
}


@include media-breakpoint-down(lg) {
    .col-overflow {
        width: 60%;
    }
    .overflow-x-auto-lg-down {
        overflow-x: auto;
    }
}

@include media-breakpoint-down(sm) {
    .col-overflow {
        width: 88%;
    }
}


picture {
    background-color: #eee;
}

.icon-left {
    max-width: 17vw;
}
.icon-left svg{
    max-width: 100%;
    height: auto;
}

.border-custom-1 {
    border-bottom: 2px dashed #d1d3d4;
}
@include media-breakpoint-up(md) {
    .border-custom-1 {
        border-bottom: none;
        border-right: 2px dashed #d1d3d4;
    }
}

.border-custom-2 {
    border-bottom: 2px solid #D1D3D4;
}
@include media-breakpoint-up(md) {
    .border-custom-2 {
        border-bottom: none;
        border-right: 2px solid #D1D3D4;
    }
}


.list-labels li a {
    padding: 8px 10px;
    color: currentColor;
    text-decoration: none;
    border: 1px solid currentColor;
    padding: 8px 10px;
    display: inline-block;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 700;

    background-image: linear-gradient(90deg, var(--bs-purple), var(--bs-purple));
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% 0%;
    transition: background-size 0.3s, color 0.4s;
}

.list-labels li.active a {
    pointer-events: none;
}
.list-labels li a:hover, .list-labels li.active a {
    background-size: 100% 100%;
    background-position: bottom;
    color: #fff;
}

.flex-shrink-0 svg {
    display: block;
}

.floating-image svg {
    perspective: 500px;
    width: 100%;
}