

/* Scrollbar */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--bs-teal) var(--bs-blue);
}

*::-webkit-scrollbar {
width: 1px;
}

*::-webkit-scrollbar-track {
    background: var(--bs-blue);
    border-radius: 99px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--bs-blue);
    border-radius: 20px;
}

/* Highlight selection */
::selection {
    color: #fff;
    background: var(--bs-cyan);
}