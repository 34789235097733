/* ------------------------- Split Words -------------------------------------------------- */
.split-words {
   position: relative;
   transform: translateY(0) translateZ(0) rotate(0.001deg);
   overflow: hidden;
   line-height: 1;
}

.split-words .single-word {
   position: relative;
   overflow: hidden;
   transform: translateY(10%) rotate(0.001deg);
   margin: -18px 0 0px 0;
   padding: 9px 0 7px 0;
}

.split-words .single-word .single-word-inner {
   position: relative;
   transform: translateY(6%) rotate(0.001deg);
   transform-origin: left top;
   will-change: transform;
}


/* ------------------------- pageTransitionLeave -------------------------------------------------- */

// Rotate slide in
.revealLeave {
   position: fixed;
   top: 0;
   z-index: 9998;
   transform: translateY(100%) rotate(0.0001deg);
   transform-origin: top left;
   width: 100%;
   height: 100%;
   transition: transform var(--animation-primary-expo);
}
.pageTransitionLeave .revealLeave {
   transform: translateY(0%) rotate(0.0001deg);
}

.pageTransitionLeave .revealEnter {
   display: none;
}



/* ------------------------- pageTransitionEnter -------------------------------------------------- */
// Background image zoom in from center
.animateBackground img,
.animateBackground video {
   transform-origin: center center;
}

// Rotate slide in
.revealEnter {
   position: fixed;
   z-index: 9998;
   transform: translateY(0%) rotate(0.0001deg);
   transform-origin: top center;
   width: 100%!important;
   height: 100%!important;
   transition: transform var(--animation-primary-expo);
}
.pageTransitionEnter .revealEnter {
   transform: translateY(-100%) rotate(0.0001deg);
}

// Opacity fade in
.animateOpacity {
   will-change: opacity;
   opacity: 0;
   transition:opacity 0.6s linear 0.1s;
}
.pageTransitionEnter .animateOpacity, 
.pageTransitionLeave .animateOpacity {
   opacity: 1;
}

