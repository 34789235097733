html.lenis {
    height: auto;
}

.lenis.lenis-smooth {
    scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

@include media-breakpoint-down(lg) {
    .lenis.lenis-smooth [data-lenis-prevent-lg-down] {
        overscroll-behavior: contain;
    }
}

.lenis.lenis-stopped {
    overflow: hidden;

}

.lenis.lenis-scrolling iframe {
    pointer-events: none;
}