/* ------------------------- Viewportheight -------------------------------------------------- */
:root {
    --vh-in-px: 8px;
    --vh: var(--vh-in-px);
}
@media (hover: hover) {
    :root {
        --vh: 1vh;
    }
    @supports (height: 1svh) { 
        :root {
            --vh: 1svh;
        }
    }
}
 

hr {
    margin: 0;
    border-color: currentColor;
    opacity:1
}

picture {
    display: block;
}



.full-vh {
    min-height: 100svh;
    min-height: calc(var(--vh) * 100);
}

.object-fit-cover {
    object-fit: cover;
}

.inset-0 {
    inset:0
}

.cursor-pointer {
    cursor: pointer;
}

.user-select-none {
    user-select: none;
}

.min-w-100 {
    min-width: 100%;
}

.opacity-10 {
    opacity: 0.1;
}

.opacity-20 {
    opacity: 0.2;
}

.text-transparent {
    color: transparent;
}

.mix-bend-mode-difference {
    mix-blend-mode: difference;
}

.mix-bend-mode-overlay {
    background-blend-mode: overlay;
 }

.z-3 {
    z-index: 3;
}

.z-4 {
    z-index: 4;
}

.z-5 {
    z-index: 5;
}

.z-6 {
    z-index: 6;
}

.z-7 {
    z-index: 7;
}

.z-01 {
    z-index: 1!important;
    
}
.z-02 {
    z-index: 2!important;
    
}
.z-03 {
    z-index: 3!important;
}
.z-04 {
    z-index: 4!important;
}

.min-vh-80 {
    min-height: 80vh;
}

.overflow-clip {
    overflow: clip;
}

.lh-ssm {
    line-height: 1;
}

@include media-breakpoint-up(md) {
    .position-md-absolute {
        position: absolute;
    }
}


.min-h-100 {
    min-height: 100%;
}

@include media-breakpoint-up(md) {
    .border-md-start {
        border-left: 1px solid;
    }
}

@include media-breakpoint-down(md) {
    .border-md-top-down {
        border-top: 1px solid;
    }
}


@include media-breakpoint-down(md) {
    .notransform-md-down {
        transform: none!important;
    }
}