.breadcrumb{
    font-weight: 600;
    flex-wrap: wrap;
    gap: 2px 0;
    font-size: 14px;
}
.breadcrumb svg {
    color: currentColor;
    transition: scale 0.5s;
    position: relative;
    top: -2px;
}
.breadcrumb a:hover svg {
    scale:1.1
}

.breadcrumb a {
    text-decoration: none;
}

.breadcrumb li a {
    display: inline-block;
    position: relative;
    color: currentColor;
    text-decoration: none;
    // text-decoration: none;
    // text-underline-position: under;
    // text-decoration-thickness: 1px; 
}
.breadcrumb li:first-child a:before {
    display: none;
}
.breadcrumb li a:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: currentColor;
    transform: translateY(0) scaleX(1);
    opacity: 1;
    display: inline-block;
    transition: transform 0.3s, opacity 0.3s;
    transform-origin: right;
}
.breadcrumb li a:hover:before {
    opacity: 1;
    transform: translateY(0) scaleX(0);
}

@include media-breakpoint-down(md) {
    .breadcrumb{
        font-size: 14px;
    }
}
@include media-breakpoint-down(sm) {
    .breadcrumb{
        font-size: 12px;
    }
}




.breadcrumb li:not(:last-child):after {
    margin-left: 8px;
    margin-right: 8px;
    color: currentColor;
    content: "/";
    display: inline-block;
}


.breadcrumb a:hover {
    text-decoration: none!important;
}

