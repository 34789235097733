/*! Flickity v2.3.0
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: #000;
  border: none;
  color: #fff;
  transform-origin: center;
  transition: background-color 0.4s;
}

.flickity-button:hover {
  background: var(--color-purple);
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: none;
}

.flickity-button:active {
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 45%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous { left: 20px; }
.flickity-prev-next-button.next { right: 20px; }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 32%;
  top: 20%;
  width: 60%;
  height: 60%;
  width: 15px;
}

/* ---- page dots ---- */

.flickity-page-dots {
  width: 100%;
  padding: 0;
  margin: 20px 0 0 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  cursor: pointer;
  position: relative;
  border-radius: 99px;
}

.flickity-page-dots .dot:after {
  content: '';
  top:50%;
  left: 0;
  height: 10px;
  width: 100%;
  background: #fff;
  opacity: 0.2;
  position: absolute;
  border-radius: 99px;
  transition:opacity 0.4s
}

.text-dark .flickity-page-dots .dot:after {
background-color: #000;
}

.flickity-page-dots .dot.is-selected:after, .flickity-page-dots .dot:hover:after {
  opacity: 1;
}