.marquee-group {
    position: relative;
 }
 
 .marquee {
    position: relative;
 }
 
 .marquee .marquee-scroll {
    position: relative;
    display: flex;
    width: 140%;
    margin-left: -20%;
    white-space: nowrap;
    flex-wrap: nowrap;
    //will-change: transform;
 }
 
 .marquee .marquee-content {
    position: relative;
    display: flex;
    will-change: transform;
 }
 
 .marquee .marquee-item {
    //width: 15vw;
 }

 .marquee-item-logo {
    width: calc(150px + 5.5vw);
    display: flex;
    align-items: center;
    justify-content: center;
 }

 .marquee-item-logo img {
    max-width: 60%;
    max-height: 80%;
 }