:root {
    --headings-margin-top: 0px;
    --headings-font-family: var(--bs-font-secondary);
    --headings-font-weight: 700;
    --headings-line-height: 1.2;
    --bs-heading-color: var(--bs-blue);
}

%heading {
    margin-top: var(--headings-margin-top);
    @include margin-bottom(1.5rem);
    font-family: var(--headings-font-family);
    font-style: var(--headings-font-style);
    font-weight: var(--headings-font-weight);
    line-height: var(--headings-line-height);
    color: var(--bs-heading-color);
    //@include margin-bottom(3rem);
}

@include media-breakpoint-down(md) {
    %heading {
        @include margin-bottom(3rem);
    }
    
}


.font-secondary {
    font-family: var(--bs-font-secondary);
}

h1, .h1,
h2, .h2 {
    font-weight: 400;
}

.big {
    @include font-size(5.4375rem);
}

.big.split-words .single-word{
    margin: -18px 0 -4px 0;
    padding: 9px 0 11px 0;
}

@include media-breakpoint-down(md) {
    h1, .h1 {
        @include font-size(6rem);
    }
    h2, .h3 {
        @include font-size(5rem);
    }
}

.lead {
    font-family: var(--bs-font-secondary);
    @include margin-bottom(1.7rem);
    line-height: 1.4;
}

@include media-breakpoint-down(md) {
    .lead {
        @include margin-bottom(6rem);
    }
}

small,.small {
    line-height: 1.3
}

.text-lg {
    @include font-size(1.25rem);
}

.text-sm {
    font-size: 16px;
}
.text-xsm, .xx-small {
    font-size: 14px;
}
@include media-breakpoint-down(md) {
    .display-1 {
        @include font-size(15rem);
    }
}
