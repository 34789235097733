    .section-nav {
        z-index: 2;
    }
    
    .scrollspy-nav {
        height: 100%;
        display: flex;
        align-items: self-start;
    }
    
    .nav-secondary {
        list-style: none;
        padding: 0;
        margin: 0;
        position: relative;
    }
    
    .nav-secondary:before {
        content: '';
        width: 1px;
        position: absolute;
        left: 0;
        top: 10px;
        bottom: 0;
        bottom: 30px;
        background: #666;
        //background: linear-gradient(rgba(0,0,0,0.4) 49.48%, rgba(255, 255, 255, 0.00) 100%);
        transition: background 0.4s
    }
    
    .nav-secondary:after {
        content: '';
        width: 17px;
        height: 17px;
        position: absolute;
        left: -8px;
        top: 4px;
        border: 1px solid currentColor;
        border-radius: 0;
        transform: translateY(0) scale(0);
        transition: transform 0.8s;
    }
    
    .nav-secondary.nav-item-1-active:after {
        transform: translateY(0) scale(1)
    }
    
    .nav-secondary.nav-item-2-active:after {
        transform: translateY(45px) scale(1)
    }
    
    .nav-secondary.nav-item-3-active:after {
        transform: translateY(90px) scale(1)
    }
    
    .nav-secondary.nav-item-4-active:after {
        transform: translateY(135px) scale(1)
    }
    
    .nav-secondary.nav-item-5-active:after {
        transform: translateY(180px) scale(1)
    }
    
    .nav-secondary.nav-item-6-active:after {
        transform: translateY(225px) scale(1)
    }
    
    .nav-secondary.nav-item-7-active:after {
        transform: translateY(270px) scale(1)
    }
    
    .nav-secondary.nav-item-8-active:after {
        transform: translateY(315px) scale(1)
    }
    
    .nav-secondary.nav-item-9-active:after {
        transform: translateY(360px) scale(1)
    }
    
    .nav-secondary.nav-item-10-active:after {
        transform: translateY(405px) scale(1)
    }
    
    
    
    .nav-secondary li {
        margin-bottom: 16px;
        position: relative;
        height: 29px;
    }
    
    .nav-secondary li a {
        white-space: nowrap;
        font-size: 15px;
        padding: 10px 0 10px 20px;
        font-weight: 500;
        text-decoration: none;
        position: relative;
        top: -3px;
        transition: color 0.4s;
        display: inline-block;
        line-height: 1.2;
        opacity: 0.5;
        transition: opacity 0.4s;
    }
    
    .nav-secondary li a span {
        display: inline-block;
        transform: translateY(-2px);
    }
    
    
    .nav-secondary li:before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        height: 0;
        width: 1px;
        background-color: currentColor;
        transition: height 0.8s
    }
    
    .nav-secondary li.was-active:before {
        height: 40px;
    }
    
    .nav-secondary li.active a,
    .nav-secondary li a:hover {
        opacity: 1;
    }
    
    .nav-secondary li.was-active:after {
        opacity: 1;
    }
    
    
    
    
    
    .nav-secondary li:after {
        content: '';
        position: absolute;
        left: -2px;
        top: 10px;
        height: 5px;
        width: 5px;
        border-radius: 0;
        background-color: #666;
        transition: transform 0.8s 0.2s, background-color 0.3s 0s, rotate 0.8s 0.2s;
    }
    
    .nav-secondary li.active:after {
        transform: scale(1.8);
        background-color: currentColor;
    }
    
    .nav-secondary li.was-active:after {
        background-color: currentColor;
        transform: scale(1.8);
    }