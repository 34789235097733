:root{
    --padding-container: 20px;
    --padding-section: 60px;
}
@include media-breakpoint-up(sm) {
    :root{
        --padding-container: 6vw;
        --padding-section: 8vw;
    }
}
@include media-breakpoint-up(lg) {
    :root{
        --padding-container: 4vw;
        --padding-section: 6vw;
    }
}
@include media-breakpoint-up(xxl) {
    :root{
        --padding-container: 7vw;
        --padding-section: 7vw;
    }
}

.container {padding-left: var(--padding-container);padding-right: var(--padding-container);}
section, .section {padding-top: var(--padding-section);padding-bottom: var(--padding-section);}



.inner {
    max-width: 1400px;
    margin: 0 auto;
}



/* Breakouts */
.breakout {
    margin-left: calc(var(--padding-container) * -1);
    margin-right: calc(var(--padding-container) * -1); 
    width: calc(100% + var(--padding-container)*2)
}

.breakout-half {
    margin-left: calc(var(--padding-container) * -0.5);
    margin-right: calc(var(--padding-container) * -0.5); 
    width: calc(100% + var(--padding-container))
}

.breakout-start {
    margin-left: calc(var(--padding-container) * -1);
    width: calc(100% + var(--padding-container))
}

.breakout-end {
    margin-right: calc(var(--padding-container) * -1); 
    width: calc(100% + var(--padding-container))
}

/* Breakouts up */
@include media-breakpoint-up(sm) {
    .breakout-sm {
        margin-left: calc(var(--padding-container) * -1);
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container)*2)
    }
    .breakout-sm-start {
        margin-left: calc(var(--padding-container) * -1);
        width: calc(100% + var(--padding-container))
    }
    .breakout-sm-end {
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container))
    }
}

@include media-breakpoint-up(md) {
    .breakout-md {
        margin-left: calc(var(--padding-container) * -1);
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container)*2)
    }
    .breakout-md-start {
        margin-left: calc(var(--padding-container) * -1);
        width: calc(100% + var(--padding-container))
    }
    .breakout-md-end {
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container))
    }
}

@include media-breakpoint-up(lg) {
    .breakout-lg {
        margin-left: calc(var(--padding-container) * -1);
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container)*2)
    }
    .breakout-lg-start {
        margin-left: calc(var(--padding-container) * -1);
        width: calc(100% + var(--padding-container))
    }
    .breakout-lg-end {
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container))
    }

    .breakout-lg-half {
        margin-left: calc(var(--padding-container) * -0.5);
        margin-right: calc(var(--padding-container) * -0.5); 
        width: calc(100% + var(--padding-container))!important
    }

    .breakout-lg-half   {
        margin-left: calc(var(--padding-container) * -0.5);
        margin-right: calc(var(--padding-container) * -0.5); 
        width: calc(100% + var(--padding-container))!important
    }

    .breakout-lg-half-start {
        margin-left: calc(var(--padding-container) * -0.5);
        width: calc(100% + var(--padding-container) * 0.5)!important
    }

    
}

@include media-breakpoint-up(xl) {
    .breakout-xl {
        margin-left: calc(var(--padding-container) * -1);
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container)*2)
    }
    .breakout-xl-start {
        margin-left: calc(var(--padding-container) * -1);
        width: calc(100% + var(--padding-container))
    }
    .breakout-xl-end {
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container))
    }
}

@include media-breakpoint-up(xxl) {
    .breakout-xxl {
        margin-left: calc(var(--padding-container) * -1);
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container)*2)
    }
    .breakout-xxl-start {
        margin-left: calc(var(--padding-container) * -1);
        width: calc(100% + var(--padding-container))
    }
    .breakout-xxl-end {
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container))
    }
}


/* Breakouts down */
@include media-breakpoint-down(sm) {
    .breakout-sm-down {
        margin-left: calc(var(--padding-container) * -1);
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container)*2)
    }
    .breakout-sm-start-down {
        margin-left: calc(var(--padding-container) * -1);
        width: calc(100% + var(--padding-container))
    }
    .breakout-sm-end-down {
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container))
    }
}

@include media-breakpoint-down(md) {
    .breakout-md-down {
        margin-left: calc(var(--padding-container) * -1);
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container)*2)
    }
    .breakout-md-start-down {
        margin-left: calc(var(--padding-container) * -1);
        width: calc(100% + var(--padding-container))
    }
    .breakout-md-end-down {
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container))
    }
}

@include media-breakpoint-down(lg) {
    .breakout-lg-down {
        margin-left: calc(var(--padding-container) * -1);
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container)*2)
    }
    .breakout-lg-start-down {
        margin-left: calc(var(--padding-container) * -1);
        width: calc(100% + var(--padding-container))
    }
    .breakout-lg-end-down {
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container))
    }
}

@include media-breakpoint-down(xl) {
    .breakout-xl-down {
        margin-left: calc(var(--padding-container) * -1);
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container)*2)
    }
    .breakout-xl-start-down {
        margin-left: calc(var(--padding-container) * -1);
        width: calc(100% + var(--padding-container))
    }
    .breakout-xl-end-down {
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container))
    }
}

@include media-breakpoint-down(xxl) {
    .breakout-xxl-down {
        margin-left: calc(var(--padding-container) * -1);
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container)*2)
    }
    .breakout-xxl-start-down {
        margin-left: calc(var(--padding-container) * -1);
        width: calc(100% + var(--padding-container))
    }
    .breakout-xxl-end-down {
        margin-right: calc(var(--padding-container) * -1); 
        width: calc(100% + var(--padding-container))
    }
}