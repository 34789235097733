img.lazy{
    opacity: 0;
    transition: opacity 0.6s;
    will-change: opacity;  
}

img.lazy.loaded{
    will-change: opacity;  
    opacity: 1;
}
